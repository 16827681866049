/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'core-js/stable';
import 'cocoon';
import 'regenerator-runtime/runtime';
import Turbolinks from 'turbolinks';
import Dropzone from 'dropzone';
import videojs from 'video.js/dist/video.min.js';
import {selectize} from '@selectize/selectize/dist/js/selectize';
import 'fine-uploader';
import moment from 'moment';
import {loadStripe} from '@stripe/stripe-js/dist/stripe.js';
import '../../../node_modules/bootstrap-datepicker/dist/js/bootstrap-datepicker';
import '../../../node_modules/bootstrap-datetimepicker/src/js/bootstrap-datetimepicker';
import '../../../node_modules/recurring-select/dist';
import 'trix';
import 'foundation-sites';
import '../src/javascripts/welcome.js';
import '../src/javascripts/jquerytimepicker.js';
import toastr from '../../../node_modules/toastr/toastr';
// import AmplitudeBrowser from '../../../node_modules/@amplitude/analytics-browser/lib/esm/browser-client.js';
import * as amplitude from '@amplitude/analytics-browser';
import flatpickr from '../../../node_modules/flatpickr/dist/flatpickr';
import MapboxGeocoder from '../../../node_modules/@mapbox/mapbox-gl-geocoder';
import Swal from '../../../node_modules/sweetalert2/dist/sweetalert2';
import ClipboardJS from '../../../node_modules/clipboard/dist/clipboard';
import lightGallery from '../../../node_modules/lightgallery.js/dist/js/lightgallery';
import lgThumbnail from '../../../node_modules/lg-thumbnail.js/dist/lg-thumbnail';
import lgZoom from '../../../node_modules/lg-zoom.js/dist/lg-zoom';
import mapkit from '../../../node_modules/mapkit.js/mapkit.js';
import Analytics from '../../../node_modules/analytics';
import googleTagManager from '../../../node_modules/@analytics/google-tag-manager';
import { urlFor } from './sanity_image_url';
import '../../../node_modules/intersection-observer';

require("bootstrap");
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require('webpack-jquery-ui');
var jQuery = require("jquery");

// import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

window.toastr = toastr
window.flatpickr = flatpickr;
window.moment = moment;
window.loadStripe = loadStripe;
window.Swal = Swal;
window.mapbox = MapboxGeocoder;
window.Analytics = Analytics;
window.googleTagManager = googleTagManager;
window.selectize = selectize;
window.amplitude = amplitude;
window.videojs = videojs;

// window.IntersectionObserver = IntersectionObserver;

// require("jquery-ui")
Turbolinks.start();
require.context('../images', true);
require("chartkick")
require("chart.js")
require("clipboard")
require('./nested-forms/addFields')
require('./nested-forms/removeFields')

// require("recurring_select")

window.urlFor = urlFor;
// console.log('amplitude start', amplitude)

window.clicked = function clicked(name, options = {}, element = null) {
  // console.log('clicked here --', name, options, element)
  
  if (element) {
    const parent = element.parentElement; // Select the parent element
    if (parent && parent.dataset.id && parent.dataset.type) {
      sendUserEngagement(parent);
    } else {
      console.warn('Parent element does not have the required dataset attributes.');
    }
  } else {
    // console.warn('Clicked element is null.');
  }

  const encryptedUserId = getCookie('eoident');
  // console.log('eoident', encryptedUserId)

  if (encryptedUserId) {
    var extractUserId = extractPublicUid(encryptedUserId)
    amplitude.setUserId(extractUserId);
    var result = amplitude.track(name, options)
    // console.log("amplitude is - ", result)
  } else {
    amplitude.track(name, options)
  } 
}

window.sendUserEngagement = function sendUserEngagement(element) {
    // console.log('sending engagement', element)
    const engagementData = {
      record_id: element.dataset.id,
      record_type: element.dataset.type,
      viewed_at: new Date().toISOString(),
      device_type: getDeviceType(),
      device_os: getDeviceOS(),
      device_screen_size: getDeviceScreenSize(),
      page_or_screen: window.location.pathname,
      user_engagement: true, // Set engagement flag
    };

    fetch("/api/services/v1/impressions/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ impressions: [engagementData] }), // Send as single impression
    })
      .then((response) => {
        if (response.ok) {
          // console.log("User engagement sent successfully");
        } else {
          // console.error("Failed to send user engagement", response.statusText);
        }
      })
      .catch((err) => console.error("Failed to send user engagement:", err));
  }

  window.getDeviceType = function getDeviceType() {
    if (window.innerWidth <= 768) return "mobile phones";
    if (window.innerWidth <= 1024) return "tablets";
    return "desktop";
  }

  window.getDeviceOS = function getDeviceOS() {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes("windows")) return "Windows";
    if (userAgent.includes("mac os")) return "macOS";
    if (userAgent.includes("android")) return "Android";
    if (userAgent.includes("iphone") || userAgent.includes("ipad")) return "iOS";
    return "unknown";
  }

  window.getDeviceScreenSize = function getDeviceScreenSize() {
    return `${window.innerWidth}x${window.innerHeight}`;
  }

  function getCookie(name) {
    const value = document.cookie
      .split('; ')
      .find((row) => row.startsWith(name + '='))
      ?.split('=')[1];
    return value ? decodeURIComponent(value) : null;
  }

  function extractPublicUid(value) {
    // Match the dynamic value between the prefix "eo" and the suffix "47862"
    const match = value.match(/^eo(.+?)47862$/);
    return match ? match[1] : null; // Return the matched dynamic value or null if no match
  }

  // Access the encrypted user ID
 

$(function () {
  $(document).foundation();

  var clip = new ClipboardJS('.btn');

  $(document).on('touchend', '.mobile-hamburger', function(){
    $('.mobile-menu').removeClass('menu-inactive');
  })

  $(document).on('touchend', '.menu-close', function(){
    $('.mobile-menu').addClass('menu-inactive');
  })



  var data = [
    { id: 'Breakfast & Brunch', text: 'Breakfast & Brunch' },
    { id: 'Nightlife', text: 'Nightlife' },
    { id: 'Bar & Lounge', text: 'Bar & Lounge' },
    { id: 'Pizza', text: 'Pizza' },
    { id: 'American (Traditional)', text: 'American (Traditional)' },
    { id: 'Coffee & Tea', text: 'Coffee & Tea' },
    { id: 'Bakery', text: 'Bakery' },
    { id: 'Dessert (ice cream)', text: 'Dessert (ice cream)' },
    { id: 'Beer, Wine, & Spirits', text: 'Beer, Wine, & Spirits' },
    { id: 'Burgers', text: 'Burgers' },
    { id: 'Cafe', text: 'Cafe' },
    { id: 'Seafood', text: 'Seafood' },
    { id: 'Soul Food', text: 'Soul Food' },
    { id: 'Cajun/Creole', text: 'Cajun/Creole' },
    { id: 'BBQ', text: 'BBQ' },
    { id: 'Chicken', text: 'Chicken' },
    { id: 'Mexican', text: 'Mexican' },
    { id: 'Carribean', text: 'Carribean' },
    { id: 'Sandwiches', text: 'Sandwiches' },
    { id: 'Specialty', text: 'Specialty' },
    { id: 'Tai', text: 'Tai' },
    { id: 'Asian', text: 'Asian' },
    { id: 'Indian', text: 'Indian' },
    { id: 'African', text: 'African' },
    { id: 'Vegan', text: 'Vegan' },
    { id: 'Haitian', text: 'Haitian' },
    { id: 'West Indian', text: 'West Indian' },
    { id: 'Music', text: 'Music' },
    { id: 'Live Music', text: 'Live Music' },
    { id: 'French', text: 'French' },
    { id: 'Southern', text: 'Southern' },
    { id: 'Vegetarian', text: 'Vegetarian' },
    { id: 'Juice & Smoothies', text: 'Juice & Smoothies' },
    { id: 'Jamacian', text: 'Jamacian' },
  ]

  var mapStyles = [
    {
      "featureType": "administrative.neighborhood",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#3c3c3c"
        }
      ]
    },
    {
      "featureType": "poi.attraction",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#3c3c3c"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#9ad6a0"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#fc9e72"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#ffbb88"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#fcb574"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "weight": 1
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#7cc1e7"
        }
      ]
    }
  ];

  var states_json = [
    {
      "name": "Alabama",
      "abbreviation": "AL"
    },
    {
      "name": "Alaska",
      "abbreviation": "AK"
    },
    {
      "name": "American Samoa",
      "abbreviation": "AS"
    },
    {
      "name": "Arizona",
      "abbreviation": "AZ"
    },
    {
      "name": "Arkansas",
      "abbreviation": "AR"
    },
    {
      "name": "California",
      "abbreviation": "CA"
    },
    {
      "name": "Colorado",
      "abbreviation": "CO"
    },
    {
      "name": "Connecticut",
      "abbreviation": "CT"
    },
    {
      "name": "Delaware",
      "abbreviation": "DE"
    },
    {
      "name": "District Of Columbia",
      "abbreviation": "DC"
    },
    {
      "name": "Federated States Of Micronesia",
      "abbreviation": "FM"
    },
    {
      "name": "Florida",
      "abbreviation": "FL"
    },
    {
      "name": "Georgia",
      "abbreviation": "GA"
    },
    {
      "name": "Guam",
      "abbreviation": "GU"
    },
    {
      "name": "Hawaii",
      "abbreviation": "HI"
    },
    {
      "name": "Idaho",
      "abbreviation": "ID"
    },
    {
      "name": "Illinois",
      "abbreviation": "IL"
    },
    {
      "name": "Indiana",
      "abbreviation": "IN"
    },
    {
      "name": "Iowa",
      "abbreviation": "IA"
    },
    {
      "name": "Kansas",
      "abbreviation": "KS"
    },
    {
      "name": "Kentucky",
      "abbreviation": "KY"
    },
    {
      "name": "Louisiana",
      "abbreviation": "LA"
    },
    {
      "name": "Maine",
      "abbreviation": "ME"
    },
    {
      "name": "Marshall Islands",
      "abbreviation": "MH"
    },
    {
      "name": "Maryland",
      "abbreviation": "MD"
    },
    {
      "name": "Massachusetts",
      "abbreviation": "MA"
    },
    {
      "name": "Michigan",
      "abbreviation": "MI"
    },
    {
      "name": "Minnesota",
      "abbreviation": "MN"
    },
    {
      "name": "Mississippi",
      "abbreviation": "MS"
    },
    {
      "name": "Missouri",
      "abbreviation": "MO"
    },
    {
      "name": "Montana",
      "abbreviation": "MT"
    },
    {
      "name": "Nebraska",
      "abbreviation": "NE"
    },
    {
      "name": "Nevada",
      "abbreviation": "NV"
    },
    {
      "name": "New Hampshire",
      "abbreviation": "NH"
    },
    {
      "name": "New Jersey",
      "abbreviation": "NJ"
    },
    {
      "name": "New Mexico",
      "abbreviation": "NM"
    },
    {
      "name": "New York",
      "abbreviation": "NY"
    },
    {
      "name": "North Carolina",
      "abbreviation": "NC"
    },
    {
      "name": "North Dakota",
      "abbreviation": "ND"
    },
    {
      "name": "Northern Mariana Islands",
      "abbreviation": "MP"
    },
    {
      "name": "Ohio",
      "abbreviation": "OH"
    },
    {
      "name": "Oklahoma",
      "abbreviation": "OK"
    },
    {
      "name": "Oregon",
      "abbreviation": "OR"
    },
    {
      "name": "Palau",
      "abbreviation": "PW"
    },
    {
      "name": "Pennsylvania",
      "abbreviation": "PA"
    },
    {
      "name": "Puerto Rico",
      "abbreviation": "PR"
    },
    {
      "name": "Rhode Island",
      "abbreviation": "RI"
    },
    {
      "name": "South Carolina",
      "abbreviation": "SC"
    },
    {
      "name": "South Dakota",
      "abbreviation": "SD"
    },
    {
      "name": "Tennessee",
      "abbreviation": "TN"
    },
    {
      "name": "Texas",
      "abbreviation": "TX"
    },
    {
      "name": "Utah",
      "abbreviation": "UT"
    },
    {
      "name": "Vermont",
      "abbreviation": "VT"
    },
    {
      "name": "Virgin Islands",
      "abbreviation": "VI"
    },
    {
      "name": "Virginia",
      "abbreviation": "VA"
    },
    {
      "name": "Washington",
      "abbreviation": "WA"
    },
    {
      "name": "West Virginia",
      "abbreviation": "WV"
    },
    {
      "name": "Wisconsin",
      "abbreviation": "WI"
    },
    {
      "name": "Wyoming",
      "abbreviation": "WY"
    }
  ];
});
