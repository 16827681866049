
///////////////////////////////
window.Dropzone = require('dropzone/dist/min/dropzone.min');

var fadeCounter = -1;

function fader(){
  if(fadeCounter === 3){
    fadeCounter = -1;
  }

  ++fadeCounter

  setTimeout(function(){//timeout
    if( fadeCounter !== 1 ){
      $(".p1" ).css('opacity', '0');
      $(".front_iphone img:nth-child(2)" ).css('opacity', '0');
    }
    if( fadeCounter !== 2 ){
      $(".p2" ).css('opacity', '0');
      $(".front_iphone img:nth-child(3)" ).css('opacity', '0');
    }
    if( fadeCounter !== 3 ){
      $(".p3" ).css('opacity', '0');
      $(".front_iphone img:nth-child(4)" ).css('opacity', '0');
    }
  },1000);//timeout

    $(".p" + fadeCounter ).css('opacity', '1');
    $(".front_iphone img:nth-child(" + (fadeCounter+1) + ")" ).css('opacity', '1');
};

///////////////////////////////

setInterval( fader, 4000);



/////////////////

$( window ).resize(function() {
  var viewport = $(this).width();
  if( viewport > 1000){
    window.scrollTo(0, 0);
    $(".nav-menu").fadeOut(0);
  }
});

///////////////////////////////


$(document).on('click', ".nav-btn",function(){
  $(".nav-menu1").fadeIn(300);
});

$(document).on('click', ".nav-menu1",function(){
  $(this).fadeOut(300);
});

document.addEventListener("DOMContentLoaded", () => {
  const video = document.getElementById("video-background");
  if (video && !video.muted) {
    video.muted = true;
  }
});


////////////////////////////////
